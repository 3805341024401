import { assertSiteWideBanner, SitewideBanner } from '@/types/Contentful/SitewideBanner'
import { FEATURE_TOGGLES } from '@/services/Configuration'
import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

const defaultVallues = {
  type: 'enhancedBanner',
  id: 'sitewideBanner',
}

export const getSiteWideBanner: (p?: {
  type: string,
  id: string,
}) => Promise<SitewideBanner | null> = async ({
  type = defaultVallues.type,
  id = defaultVallues.id,
} = defaultVallues) => {
  if (!FEATURE_TOGGLES?.sitewide_isContentfulEnabled) {
    log('SSR getSiteWideBanner Feature Toggle disabled')
    return null
  }
  try {
    const [{ getContentWithSlug }] = await allPromisesWithRetries(() => [import('@/services/Content')])
    const banner = await getContentWithSlug<SitewideBanner>({ type, id, preview: false })
    assertSiteWideBanner(banner)
    return banner
  } catch (err) {
    error('Failed to retrieve SitewideBanner', err)
  }
  return null
}

export default getSiteWideBanner
