import { log } from '@/services/Log'

export const timePromiseAndLog = (name: string) => async <T>(promise :Promise<T>) => {
  const initialTime = new Date()
  try {
    const restult = await promise
    const finalTime = new Date()
    const ms = finalTime.getTime() - initialTime.getTime()
    log(`PROMISE_TIMING: ${name}:\tresolved in ${ms}[ms]`)
    return restult
  } catch (error) {
    const finalTime = new Date()
    const ms = finalTime.getTime() - initialTime.getTime()
    log(`PROMISE_TIMING: ${name}:\trejected in ${ms}[ms]`)
    throw error
  }
}
