import { withSentryAssertion } from '@/helpers/withSentryAssertion'

import {
  array,
  bool,
  CheckerReturnType,
  object,
  optional,
  string,
} from '@recoiljs/refine'

export const SitewideBannerTileRunType = object({
  internalName: string(),
  callToActionText: string(),
  callToActionUrl: optional(string()),
  backgroundColor: optional(string()),
  backgroundMode: optional(bool()),
  disclaimerText: optional(string()),
  showDisclaimer: optional(bool()),
  textColor: optional(string()),
  detailsText: optional(string()),
})

export type SitewideBannerTile = CheckerReturnType<typeof SitewideBannerTileRunType>

export const SitewideBannerRunType = object({
  // version: or(literal(1), literal(2)),
  messaging: string(),
  callToActionText: string(),
  callToActionUrl: optional(string()),
  countdownTarget: optional(string()),
  tiles: array(SitewideBannerTileRunType),
})

export type SitewideBanner = CheckerReturnType<typeof SitewideBannerRunType>

export const assertSiteWideBanner = withSentryAssertion([SitewideBannerRunType, 'Contentful Sitewide Banner'])
