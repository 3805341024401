import {
  CheckerReturnType, nullable, string, assertion, literal, object, asType,
} from '@recoiljs/refine'

const DateIfDefined = (
  asType(
    nullable(string()),
    (maybeDateString) => (
      maybeDateString
        ? new Date(maybeDateString)
        : null
    ),
  )
)
const StringIfDefined = (
  asType(
    nullable(string()),
    (maybeString) => (
      maybeString || null
    ),
  )
)

const stringOrDefault = (defaultString: string) => (
  asType(
    nullable(string()),
    (maybeString) => (
      maybeString || defaultString
    ),
  )
)
const EventBanner = object({
  __type: literal('eventBanner'),
  mainText: StringIfDefined,
  linkHref: StringIfDefined,
  linkText: StringIfDefined,
  vwoExperimentKey: StringIfDefined,
  startDate: DateIfDefined,
  endDate: DateIfDefined,
  backgroundColor: stringOrDefault('#D8DFDE'),
})

export type EventBannerType = CheckerReturnType<typeof EventBanner>

export const assertEventBanner = assertion(EventBanner, 'EventBanner is not valid')
