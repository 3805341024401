const stagingHost = 'okl.cscshopfront-dev.com'
const localhost = 'localhost'
/**
 * Parse the url and get the https://onekingslane.com/p-s without the query
 * Replace 'localhost' with staging host because 'localhost' causes problems on browser
 */
export const getRefererString = (referer?: string) => {
  let refererResolved = referer
  if (!referer) {
    if (typeof window !== 'undefined') {
      refererResolved = window.document.referrer
    }
  }
  let refererReplaced = ''
  if (refererResolved) {
    const refererURL = new URL(refererResolved)
    if (refererURL.hostname === localhost) {
      refererURL.hostname = stagingHost
    }
    refererReplaced = refererURL.hostname + refererURL.pathname
  }
  return refererReplaced
}

export const getCurrentUrl = (location?: { [index: string]: string }) => {
  if (typeof location !== 'undefined') {
    let { hostname } = location
    const { pathname } = location
    if (hostname === localhost) {
      hostname = stagingHost
    }
    return hostname + pathname
  }
  return ''
}
