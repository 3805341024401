import {
  CheckerReturnType, nullable, string, assertion, literal, object, asType, array, bool,
} from '@recoiljs/refine'

const StringIfDefined = (
  asType(
    nullable(string()),
    (maybeString) => (
      maybeString || null
    ),
  )
)

const FooterLink = object({
  // __type: literal('link'),
  displayText: string(),
  url: string(),
  newTab: bool(),
  clickable: bool(),
  // stagingAvailable: nullable(bool()),
  ariaLabel: nullable(string()),
})
export type FooterLinkType = CheckerReturnType<typeof FooterLink>

const FooterColumn = object({
  // __type: literal('footerColumn'),
  title: string(),
  links: array(FooterLink),
})

export type FooterColumnType = CheckerReturnType<typeof FooterColumn>

const FooterDeployment = object({
  __type: literal('footerDeployment'),
  footerVersion: StringIfDefined,
  emailHeading: StringIfDefined,
  emailByline: StringIfDefined,
  emailCta: StringIfDefined,
  emailPlaceholder: StringIfDefined,
  columns: array(FooterColumn),
  copyrightNotice: StringIfDefined,
  disclosureLinks: array(FooterLink),
  // publishAt: DateIfDefined,
  // previewPublishAt: DateIfDefined,
  accessibilityHeading: StringIfDefined,
  accessibilityMessage: StringIfDefined,
})

export type FooterDeploymentType = CheckerReturnType<typeof FooterDeployment>

export const assertFooterDeployment = assertion(FooterDeployment, 'FooterDeployment is not valid')
