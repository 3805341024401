import { getCookieByName } from './common'
import { getCurrentUrl } from './getRefererString'

const fallbackUserId = Array(15).fill(null).map(() => '8').join('')
export type BloomreachConfig = {
  useBloomreach: boolean,
  bloomreachUID: string
  url: string
}
export const getBloomreachConfig = (cookies?: string | undefined): BloomreachConfig => {
  const useBloomreach = true // getCookieByName('useBloomreach', cookies) === '1'
  const bloomreachUID = getCookieByName('_br_uid_2', cookies) || fallbackUserId
  let url = ''
  if (typeof window !== 'undefined') {
    url = getCurrentUrl(window?.location as unknown as { [index: string]: string })
  }
  return {
    useBloomreach,
    bloomreachUID,
    url,
  }
}
