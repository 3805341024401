import { GraphQLStoreFrontJWT, SHOP_ORIGIN } from '@/services/Configuration'
import { error } from '@/services/Log'
import allPromisesWithRetries from './allPromisesWithRetries'

type HeadersType = { [key: string]: string | string[] }
export const GraphQLHeaders = (headers: HeadersType = {}) => ({
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${GraphQLStoreFrontJWT}`,
  },
})

export const signUserIn = async ({ email, password }: { email: string, password: string }) => {
  let success = false
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const response = await Axios.post<{ data: { login: { result: string } } }>(
      `${SHOP_ORIGIN}/graphql`,
      {
        query: `mutation Login($email: String!, $password: String!) {
                        login(email: $email, password: $password) {
                          result
                        }
                      }
                      `,
        variables: { email, password },
      },
      GraphQLHeaders(),
    )
    success = response?.data?.data?.login?.result === 'success'
  } catch (err) {
    error('Failed to login via GRAPHQL', err)
  }
  return { success }
}

export const signUserOut = async () => {
  let success = false
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const response = await Axios.post<{ data: { logout: { result: string } } }>(
      `${SHOP_ORIGIN}/graphql`,
      {
        query: `mutation logout {
          logout {
            result
          }
        }`,
      },
      GraphQLHeaders(),
    )
    success = response?.data?.data?.logout?.result === 'success'
    return { success }
  } catch (err) {
    error('Failed to signUserOut', err)
    return { success: false }
  }
}

export type GraphQlCustomerWishlist = {
  node: {
    entityId: number
    name: string
    token: string
    isPublic: boolean
    items: {
      edges: Array<{
        node: {
          entityId: number
          productEntityId: number
          variantEntityId: number
          product: {
            sku: string
          }
        }
      }>
    }
  }
}
export type GraphQlCustomer = {
  entityId: number,
  company: string,
  customerGroupId: number,
  email: string
  firstName: string
  lastName: string,
  notes: string,
  phone: string
  taxExemptCategory: string
  addressCount: number
  attributeCount: number
  storeCredit: [{
    value: number
    currencyCode: string
  }]
  wishlists: {
    edges: Array<GraphQlCustomerWishlist>
  }
}
type AxiosGraphQLGetCustomer = {
  data: {
    customer: null | GraphQlCustomer
  }
}
type GraphQLGetCustomerRequest = (p?: { headers?: HeadersType }) =>
Promise<{ success: true, customer: GraphQlCustomer } | { success: false, customer: null }>

export const getCustomer: GraphQLGetCustomerRequest = async ({ headers = {} } = {}) => {
  try {
    const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
    const { data } = await Axios.post<AxiosGraphQLGetCustomer>(
      `${SHOP_ORIGIN}/graphql`,
      {
        query: `query customer {
          customer {
            entityId
            company
            customerGroupId
            email
            firstName
            lastName
            notes
            phone
            taxExemptCategory
            addressCount
            attributeCount
            storeCredit {
              value
              currencyCode
            }
            wishlists(first: 50) {
              edges {
                node {
                  entityId
                  isPublic
                  name
                  token
                  items(first: 50) {
                    edges {
                      node {
                        product {
                          entityId
                          sku
                        }
                        entityId
                        productEntityId
                        variantEntityId
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      },
      GraphQLHeaders(headers),
    )
    const customer = data?.data?.customer
    if (customer) {
      return { success: true, customer }
    }
  } catch {
    // fail silently
  }
  return { success: false, customer: null }
}
