import type { CategoryPageBannerContentfulResponse } from '@/types'
import { CONTENT_API, CONTENT_PREVIEW } from '@/services/Configuration'
import { error } from '@/services/Log'
import Axios from 'axios'
import { assertEventBanner } from '@/types/Contentful/EventBanner'
import { assertFooterDeployment } from '@/types/Contentful/Footer'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'

interface SlugQueryProps {
  type: string
  id?: string,
  slug?: string
  preview?: boolean
}

export const getContentWithSlug: <T>(s: SlugQueryProps) => Promise<T | null> = async <T>({
  type, id = '', slug = id, preview = true,
}: {
  type: string
  id?: string,
  slug?: string
  preview?: boolean
}) => {
  let contentWithSlug: T | null = null
  const url = `${CONTENT_API}/content/${type}/${slug}${preview ? '?preview=true' : ''}`
  try {
    contentWithSlug = (await Axios.get<T>(url))?.data
  } catch (err) {
    error('Failed to fetch content with Slug from Contentful', {
      url, type, slug, CONTENT_API,
    })
  }
  return contentWithSlug
}

export const getContentWithoutSlug = async <T>(type: string) => (await Axios.get<T>(`${CONTENT_API}/content/${type}`, { params: { preview: CONTENT_PREVIEW } })).data

export const getEventBanner = async () => {
  if (!isFeatureEnabled('eventBanner')) {
    return null
  }
  try {
    return assertEventBanner(
      (await Axios.get<unknown>(`${CONTENT_API}/content/eventBanner/`, { params: { preview: CONTENT_PREVIEW } })).data,
    )
  } catch (err) {
    error('Failed to fetch event banner from Contentful', {
      'String(err)': String(err),
      err,
    })
  }
  return null
}

export const getFooterFromContentful = async () => {
  try {
    if (isFeatureEnabled('isContentfulFooterEnabled')) {
      return assertFooterDeployment(
        (await Axios.get(`${CONTENT_API}/content/footerDeployment`, { params: { preview: CONTENT_PREVIEW } })).data,
      )
    }
  } catch (err) {
    error('Failed to fetch footer from Contentful', {
      'String(err)': String(err),
      err,
    })
  }
  return null
}

export const Content = {
  // eslint-disable-next-line no-underscore-dangle
  categoryPage: ({ id }) => getContentWithSlug<CategoryPageBannerContentfulResponse>({ type: 'categoryPage', slug: id }).then((content) => (content?.__type === 'categoryPage' ? content : null)),
  getContentWithSlug,
}

export default Content
