/* eslint-disable @typescript-eslint/no-unsafe-call */
import { error } from '@/services/Log'
import { assertion, Checker } from '@recoiljs/refine'
import { chain, thenCompose, thrown } from '@/helpers/FP'

const sentryImporter = () => import('@sentry/react')
type SentryreactPackage = Awaited<ReturnType<typeof sentryImporter>>

const sadPath = (inputName: string) => (input: unknown) => (assertProductError: unknown) => (
  thenCompose(({
    setContext, captureException,
  }: SentryreactPackage) => {
    setContext(`Failed to assert: ${inputName}`, { input })
    captureException(assertProductError)
  })(sentryImporter)(null).catch(error)
)

export const withSentryAssertion = <T>(
  [checker, inputName]: [Checker<T>, string],
) => chain(thrown(assertion(checker, inputName)))(sadPath(inputName))

export default withSentryAssertion
